import { Container, Typography } from "@material-ui/core"
import React from "react"

import SEO from "../components/seo"

const NotFoundPage = ({ location }) => (
  <section style={{ flex: "1" }}>
    <SEO location={location} title="404: Not found" />
    <Container style={{ textAlign: "center" }}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">NOT FOUND</Typography>
      <Typography variant="body1">
        You just hit a route that doesn&#39;t exist...
      </Typography>
    </Container>
  </section>
)

export default NotFoundPage
